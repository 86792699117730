import { createRouter, createWebHashHistory } from 'vue-router'
import { isPhone } from '@/utils/system/systemUtils'

const routePC = [
  {
    path: '/',
    name: 'toolbox',
    component: () => import('@/views/layout/layoutMain.vue'),
    redirect: '/toolbox',
    meta: {
      title: '悟空图像常用工具箱'
    },
    children: [
      {
        path: '/toolbox',
        name: 'toolboxHome',
        component: () => import('@/views/homePage/homePage.vue')
      },
      {
        path: '/AIPoster',
        name: 'AIPoster',
        component: () => import('@/views/homePage/homePage.vue')
      },
      // 首页ai开始生成
      {
        path: '/generate',
        name: 'generate',
        component: () => import('../views/AIRelated/startGenerate.vue')
      },
      // 抠图形
      {
        path: '/Upload',
        name: 'Upload',
        redirect: () => {
          return {
            path: '/cutFigure',
            query: {
              currentFunction: 1
            }
          }
        }
      },
      // 裁切
      {
        path: '/cutFigure',
        name: 'cutFigure',
        component: () => import('../views/cropperImage/cropperImagePc.vue')
      },
      // 模糊变清晰(超分)
      {
        path: '/superResolution',
        name: 'superResolution',
        component: () => import('@/views/superResolution/superResolutionPc.vue')
      },
      // gif相关
      {
        path: '/gifCreate',
        name: 'gifCreate',
        component: () => import('@/views/gifCreate/gifCreatePc.vue')
      },
      // 一键抠万物
      {
        path: '/imageMatting',
        name: 'imageMatting',
        component: () => import('@/views/imageMatting/mattingPc')
      },
      // 在线海报
      {
        path: '/posterEdit',
        name: 'posterEdit',
        component: () => import('../views/AIRelated/posterEdit/posterEdit.vue')
      },
      // 图片旋转
      {
        path: '/imgBatchRevolve',
        name: 'imgBatchRevolve',
        component: () => import('../views/imgBatchRevolve/imgBatchRevolvePc.vue')
      },
      // 抠图形
      {
        path: '/seal',
        name: 'seal',
        component: () => import('../views/toolbox/cutSeal.vue')
      }
    ]
  },
  // 首页ai生成历史
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/AIRelated/AIHistory.vue')
  },
  // ai开始生成
  {
    path: '/generateOutward',
    name: 'generateOutward',
    component: () => import('../views/AIRelated/startGenerate.vue')
  },
  // ai开始生成展示页
  {
    path: '/generateResult',
    name: 'generateResult',
    component: () => import('../views/AIRelated/startGenerateResult.vue')
  }
]

const routeApp = [
  {
    path: '/',
    name: 'toolbox',
    component: () => import('@/views/layout/layoutMain.vue'),
    redirect: '/toolboxHome',
    meta: {
      title: '悟空图像常用工具箱'
    },
    children: [
      {
        path: '/toolboxHome',
        name: 'toolboxHome',
        component: () => import('@/views/homePage/homePageApp.vue'),
        redirect: '/toolbox',
        children: [
          // 工具箱首页
          {
            path: '/toolbox',
            name: 'toolBoxApp',
            component: () => import('../views/homePage/toolBoxApp.vue')
          }
        ]
      },
      // 抠图形
      {
        path: '/Upload',
        name: 'Upload',
        redirect: () => {
          return {
            path: '/cutFigure',
            query: {
              currentFunction: 1
            }
          }
        }
      },
      // 裁切
      {
        path: '/cutFigure',
        name: 'cutFigure',
        component: () => import('../views/cropperImage/cropperImageApp.vue')
      },
      // 模糊变清晰(超分)
      {
        path: '/superResolution',
        name: 'superResolution',
        component: () => import('@/views/superResolution/superResolutionApp.vue')
      },
      // gif相关
      {
        path: '/gifCreate',
        name: 'gifCreate',
        component: () => import('@/views/gifCreate/gifCreateApp.vue')
      },
      // 一键抠万物
      {
        path: '/imageMatting',
        name: 'imageMatting',
        component: () => import('@/views/imageMatting/mattingH5')
      },
      // 在线海报
      {
        path: '/posterEdit',
        name: 'posterEdit',
        component: () => import('../views/AIRelated/posterEdit/posterEdit.vue')
      },
      // 图片旋转
      {
        path: '/imgBatchRevolve',
        name: 'imgBatchRevolve',
        component: () => import('../views/imgBatchRevolve/imgBatchRevolveApp.vue')
      },
      // 抠图形
      {
        path: '/seal',
        name: 'seal',
        component: () => import('../views/toolbox/cutSealApp.vue')
      }
    ]
  },
  // AI海报
  {
    path: '/AIPoster',
    name: 'AIPoster',
    component: () => import('@/views/layout/layoutMain.vue'),
    redirect: '/AIHomePageApp',
    meta: {
      title: '悟空图像AI海报'
    },
    children: [
      {
        path: '/AIHomePageApp',
        name: 'AIHomePageApp',
        component: () => import('../views/homePage/homePageApp.vue'),
        redirect: '/aiPoster',
        children: [
          {
            path: '/aiPoster',
            name: 'aiPoster',
            component: () => import('../views/homePage/aiPosterApp.vue')
          },
          {
            path: '/generate',
            name: 'generate',
            component: () => import('../views/AIRelated/startGenerateH5.vue')
          },
          {
            path: '/history',
            name: 'history',
            component: () => import('../views/AIRelated/AIHistoryApp.vue')
          }
        ]
      }
    ]
  }
]

// 创建
const router = createRouter({
  history: createWebHashHistory(),
  routes: (isPhone()) ? routeApp : routePC
})

// 路由守卫(版本暂时逻辑)
router.beforeEach((to, from, next) => {
  if (!to.meta.title) {
    window.document.title = '悟空图像常用工具箱'
  } else {
    window.document.title = to.meta.title
  }
  next()
})

export default router
