/* 画布通用设置/操作 */
import initControls from '@/utils/canvas/initControls'
import initAligningGuidelines from '@/utils/canvas/guideLines'
import { fabric } from 'fabric-with-erasing'
import { v4 as uuid } from 'uuid'
import store from '@/store'
import event from '@/bus/bus.js'

/**
 * 画布相关组件初始化
 * @param canvas 画布对象
 */
export function initCanvasPlugin (canvas) {
  initControls(canvas)// 控制条样式
  initAligningGuidelines(canvas)// 辅助线
}

/**
 * 海报图层初始化
 * @param canvas 画布对象
 * @param width  宽
 * @param height 高
 */
export function initWorkspace (canvas, width, height) {
  return new Promise((resolve) => {
    const space = new fabric.Rect({
      fill: store.state.canvasInfo.backgroundColor,
      width,
      height,
      id: 'workspace',
      strokeWidth: 0
    })
    space.set('selectable', false)
    space.set('hasControls', false)
    space.hoverCursor = 'default'
    canvas.add(space)
    resolve(space)
  })
}

/**
 * 画布事件监听初始化
 */
export function initCanvasListener (canvas) {
  // 渲染过程结束
  canvas.on('after:render', function () {
    updateCanvasInfo(canvas) // 同步全局canvas对象
  })
  // 图层添加后
  canvas.on('object:added', canvasListenerDoingForAdded)
  // 图层修改后
  canvas.on('object:modified', function (e) {
    if (e.target) {
      if (['textbox', 'group'].indexOf(e.target.type) > -1) effectLayerModify(e) // 同步艺术字图层
      store.commit('saveOperateHistory') // 保存历史
      updateLayerList() // 更新图层列表
    }
  })
  // 选中相关事件
  canvas.on('selection:created', canvasListenerDoingForSelection)
  canvas.on('selection:updated', canvasListenerDoingForSelection)
  canvas.on('selection:cleared', canvasListenerDoingForSelection)
  // 移动事件
  canvas.on('object:moving', canvasListenerDoingForMoving)
  // 旋转事件
  canvas.on('object:rotating', canvasListenerDoingForRotating)
}

/**
 * 画布事件处理 - 添加后
 * @param options
 */
function canvasListenerDoingForAdded (options) {
  if (!options.target) return
  // 添加id
  if (!options.target.id) options.target.id = uuid()
  // 文字添加事件
  if (options.target.type === 'textbox') {
    // 防止文字拉伸变形
    options.target.on('scaling', (ev) => {
      const target = ev.transform.target
      if (ev.transform.corner && ['tl', 'tr', 'bl', 'br'].indexOf(ev.transform.corner) > -1) {
        target.set('scaleXold', target.scaleX)
        target.set('scaleYold', target.scaleY)
        return
      }
      const scaleX = (target.scaleX || 1)
      const scaleY = (target.scaleY || 1)
      const scaleXold = (target.scaleXold || scaleX)
      const scaleYold = (target.scaleYold || scaleY)
      target.set('scaleXold', scaleXold)
      target.set('scaleYold', scaleYold)
      const width = (target.width || 1)
      const zoomWidthNew = width * scaleX
      const zoomWidthOld = width * scaleXold
      const newWidth = width + (zoomWidthNew - zoomWidthOld) / scaleXold
      target.set('width', newWidth)
      target.set('scaleX', scaleXold)
      target.set('scaleY', scaleYold)
    })
  }
  // 保存非模板/非特效图层操作历史
  const hasEffects = (options.target.effects && Array.isArray(options.target.effects) && options.target.effects.length)
  if (!options.target.isTemplate && !hasEffects) store.commit('saveOperateHistory')
  // 更新图层列表
  updateLayerList()
}

/**
 * 画布事件处理 - 选中
 * @param options
 */
function canvasListenerDoingForSelection (options) {
  // 更新图层列表
  updateLayerList()
  // 清除选中
  if (!options.selected || options.selected.length === 0) {
    store.commit('setCanvasInfo', { selectLayerList: [] })
    return
  }
  // 更新选中
  const selectLayerList = []
  store.state.boxCanvas.getActiveObjects().forEach(function (one) {
    if (!one.id) return
    selectLayerList.push(one)
  })
  store.commit('setCanvasInfo', { selectLayerList: selectLayerList })
  // 更新选中初始化操作界面类型
  refreshOperationFaceType('colourMixing')
  // 过滤选择元素事件(保存历史逻辑)
  const isSelect = options.action === undefined && options.e
  if (!isSelect && options.target && options.target.id) store.commit('saveOperateHistory')
}

/**
 * 画布事件处理 - 移动
 * @param e
 */
function canvasListenerDoingForMoving (e) {
  const { target } = e
  if (target.id) { // 单选
    const { left, top } = target
    const effectLayer = store.state.boxCanvas.getObjects().filter((item) => item.id.includes(target.id + '~copy'))
    if (effectLayer.length) {
      effectLayer.forEach((element) => {
        const { offsetX, offsetY, used } = element.usedInfo.stroke
        element.set('top', used ? offsetY + top : top)
        element.set('left', used ? offsetX + left : left)
      })
    }
  } else { // 多选
    if (e.target._objects) {
      const { target: { left: groupLeft, top: groupTop, width, height, _objects } } = e
      _objects.forEach(item => {
        const { id } = item
        const effectLayer = store.state.boxCanvas.getObjects().filter((item) => item.id.includes(id + '~copy'))
        if (effectLayer.length) {
          effectLayer.forEach((element) => {
            const { offsetX, offsetY, used } = element.usedInfo.stroke
            const { left, top } = item
            // 多选时，需要计算偏移量：每个图层的left和top是针对组的中心为基准的
            element.set('top', used ? offsetY + top + height / 2 + groupTop : top + height / 2 + groupTop)
            element.set('left', used ? offsetX + left + width / 2 + groupLeft : left + width / 2 + groupLeft)
          })
        }
      })
    }
  }
}

/**
 * 画布事件处理 - 旋转
 * @param e e.target.id存在=>单个图层旋转,  e.target._objects存在=>多选图层旋转，此处有bug:原因需要旋转的图层 是以原始图层的中心为基准旋转的，但此处是按照自己的原点旋转
 */
function canvasListenerDoingForRotating (e) {
  if (e.target.id) { // 单选
    const { target } = e
    const effectLayer = store.state.boxCanvas.getObjects().filter((item) => item.id.includes(target.id + '~copy'))
    if (effectLayer.length) {
      e.target.originX = 'center'
      e.target.originY = 'center'
      const Point = e.target.getCenterPoint()
      effectLayer.forEach((element) => {
        const { angle } = target
        const { used, offsetX, offsetY } = element.usedInfo.stroke
        const left = used ? e.target.left + offsetX : e.target.left
        const top = used ? e.target.top + offsetY : e.target.top
        const centerPoints = new fabric.Point(Point.x, Point.y) // 选中对象的中心位置，绕此点旋转
        const objectOrigin = new fabric.Point(left, top)
        const angleRadians = fabric.util.degreesToRadians(angle)
        const newLoc = fabric.util.rotatePoint(objectOrigin, centerPoints, angleRadians)
        element.left = newLoc.x
        element.top = newLoc.y
        element.angle = angle
        e.target.originX = 'left'
        e.target.originY = 'top'
      })
    }
  } else { // 多选
    if (e.target._objects) {
      const { target: { angle, _objects } } = e
      _objects.forEach(item => {
        const { id } = item
        const effectLayer = store.state.boxCanvas.getObjects().filter((item) => item.id.includes(id + '~copy'))
        if (effectLayer.length) {
          e.target.originX = 'center'
          e.target.originY = 'center'
          const Point = e.target.getCenterPoint()
          effectLayer.forEach((element) => {
            const { used, offsetX, offsetY } = element.usedInfo.stroke
            const left = used ? item.left + e.target.left + e.target.width / 2 + offsetX : item.left + e.target.left + e.target.width / 2
            const top = used ? item.top + e.target.top + e.target.height / 2 + offsetY : item.top + e.target.top + e.target.height / 2
            const centerPoints = new fabric.Point(Point.x, Point.y) // 选中对象的中心位置，绕此点旋转
            const objectOrigin = new fabric.Point(left, top)
            const angleRadians = fabric.util.degreesToRadians(angle)
            const newLoc = fabric.util.rotatePoint(objectOrigin, centerPoints, angleRadians)
            element.left = newLoc.x
            element.top = newLoc.y
            element.angle = angle + item.angle
            e.target.originX = 'left'
            e.target.originY = 'top'
          })
        }
      })
    }
  }
}

/**
 * 文字图层更改事件
 * @param textBox
 */
export function textLayerAddListener (textBox) {
  // 文字改变事件
  textBox.on('changed', () => {
    const { text } = textBox
    const effectLayer = store.state.boxCanvas.getObjects().filter((item) => item.id.includes(textBox.id + '~copy'))
    if (effectLayer.length) {
      effectLayer.forEach((element) => {
        element.set('text', text)
      })
    }
  })
  // 删除事件
  textBox.on('removed', () => {
    store.state.boxCanvas._objects.filter(item => item.id.includes(textBox.id + '~copy~')).forEach(a => {
      store.state.boxCanvas.remove(a)
    })
    // store.state.boxCanvas._objects = store.state.boxCanvas._objects.filter(item => !item.id.includes(textBox.id + '~copy~'))
  })
  // 选中事件
  textBox.on('selected', e => {
    store.commit('uploadSelectLayerId', e.target.id)
  })
}

/**
 * 海报属性更新
 * @param canvas 海报图层对象
 * @param data 属性对象
 */
export function workspaceUpdate (canvas, data) {
  const width = Number(data.width)
  const height = Number(data.height)
  const backgroundColor = data.backgroundColor
  const workspace = canvas.getObjects().find((item) => item.id === ('workspace'))
  if (!workspace) return
  workspace.set('selectable', false)
  workspace.set('hasControls', false)
  // 宽
  if (width) {
    workspace.set('width', width)
    store.state.canvasInfo.width = width
  }
  // 高
  if (height) {
    workspace.set('height', height)
    store.state.canvasInfo.height = height
  }
  // 背景颜色
  if (backgroundColor) {
    workspace.set('fill', backgroundColor)
    store.commit('setCanvasInfo', { backgroundColor: backgroundColor })
  }
  // 超出工作空间不展示
  workspace.clone((cloned) => {
    canvas.clipPath = cloned
    canvas.requestRenderAll()
  })
}

/**
 * 海报内容清空
 * @param canvas  总画布对象
 * @param layerId 海报图层id
 */
export function workspaceClear (canvas, layerId) {
  return new Promise((resolve) => {
    canvas.getObjects().forEach(function (one) {
      if (!one.id || one.id !== (layerId || 'workspace')) {
        canvas.remove(one)
      }
    })
    canvas.requestRenderAll()
    resolve(canvas)
  })
}

/**
 * 图层锁定
 * @param layerIdList 图层id集合
 * @param isLock  是否锁定
 */
export function layerLock (layerIdList, isLock) {
  const myCanvas = store.state.boxCanvas
  if (!myCanvas) return
  myCanvas.forEachObject(function (one) {
    if (one.id && layerIdList.indexOf(one.id) > -1) {
      if (isLock) myCanvas.discardActiveObject()
      one.set('lockMovementX', isLock) // 当设置为true时，对象的水平移动将被锁定。
      one.set('lockMovementY', isLock) // 当设置为true时，对象的垂直移动将被锁定。
      one.set('selectable', !isLock) // 当设置为false时，对象无法被选中编辑。
      one.set('hasBorders', !isLock) // 当属性为false时，边框将不会被渲染。
    }
  })
  initControls(myCanvas)
  myCanvas.requestRenderAll()
  updateLayerList()
}

/**
 * 图层删除
 * @param layerIdList 图层id集合
 */
export function layerDel (layerIdList) {
  const myCanvas = store.state.boxCanvas
  if (!myCanvas) return
  myCanvas.forEachObject(function (item) {
    if (item.id && layerIdList.indexOf(item.id) > -1) myCanvas.remove(item)
  })
  myCanvas.discardActiveObject()
  myCanvas.requestRenderAll()
}

/**
 * 图层选中
 * @param layerId 图层id
 */
export function layerSelected (layerId) {
  const myCanvas = store.state.boxCanvas
  if (!myCanvas || !layerId) return
  myCanvas.forEachObject(function (one) {
    if (one.id && one.id === layerId) {
      if (!one.selectable) return
      if (!one.visible) return
      myCanvas.discardActiveObject()
      myCanvas.setActiveObject(one)
      myCanvas.requestRenderAll()
    }
  })
  myCanvas.requestRenderAll()
}

/**
 * 图层组合
 */
export function layerGroup () {
  if (store.state.canvasInfo.selectLayerList.length < 2) return
  // let myCanvas = store.state.boxCanvas
  // if (!myCanvas) return
  // const activeObj = myCanvas.getActiveObject()
  // if (!activeObj) return
  // const activeGroup = activeObj.toGroup()
  // const objectsInGroup = activeGroup.getObjects()
  // activeGroup.clone((newGroup) => {
  //   newGroup.set('id', uuid())
  //   myCanvas.remove(activeGroup)
  //   objectsInGroup.forEach((object) => {
  //     myCanvas.remove(object)
  //   })
  //   myCanvas.add(newGroup)
  //   myCanvas.setActiveObject(newGroup)
  // })

  // step 1 : 获取当前选中对象们
  // step 2 : 获取选中对象的相关图层（文字特效，即id中带有~copy~）
  // step 3 : 选中图层和相关图层设置为group，group中id带有~copy~的图层位置需更新
  // step 4 : group加入到画布中
  const myCanvas = store.state.boxCanvas
  if (!myCanvas) return
  const activeObj = myCanvas.getActiveObject()
  const allLayer = myCanvas._objects
  let activeLayer = []
  activeObj._objects.reverse().forEach(k => {
    const ac = allLayer.filter(item => item.id.includes(k.id))
    activeLayer = [...ac, ...activeLayer]
  })
  if (!activeLayer.length) return
  //  更新选中图层的位置
  activeLayer.forEach(a => {
    const { id } = a
    if (id.includes('~copy~')) {
      const origin = activeLayer.find(k => k.id === (id.split('~copy~')[0]))
      if (origin) {
        const { left, top, angle } = origin
        a.set('left', a.usedInfo.stroke.used ? left + a.usedInfo.stroke.offsetX : left)
        a.set('top', a.usedInfo.stroke.used ? top + a.usedInfo.stroke.offsetY : top)
        a.set('angle', angle)
      }
    }
  })
  const activeGroup = activeObj.toGroup()
  const group = new fabric.Group(activeLayer, {
    id: uuid() + 'group',
    left: activeGroup.left,
    top: activeGroup.top,
    angle: activeGroup.angle,
    visible: true
  })
  // 删除旧组
  myCanvas.remove(activeGroup)
  // 添加新组
  myCanvas.add(group).setActiveObject(group)
  myCanvas.renderAll()
  // 刷新选中
  const selectObjects = myCanvas.getActiveObjects()
  const activeSelection = new fabric.ActiveSelection(selectObjects, {
    canvas: myCanvas
  })
  myCanvas.discardActiveObject()
  myCanvas.setActiveObject(activeSelection)
  myCanvas.discardActiveObject()
  myCanvas.requestRenderAll()
}

/**
 * 图层拆分组合
 */
export function layerUnGroup () {
  const myCanvas = store.state.boxCanvas
  if (!myCanvas) return
  const activeObject = myCanvas.getActiveObject()
  if (!activeObject) return
  if (myCanvas.getActiveObjects().length === 1) { // 单选
    activeObject.toActiveSelection()
  } else { // 多选
    activeObject._objects.forEach(function (item) {
      if (item._objects) item.toActiveSelection()
      if (!item.id) item.set('id', uuid())
    })
  }
  myCanvas.discardActiveObject().renderAll()
}

/**
 * 图层对齐
 * @param name 操作名称
 */
export function layerAlign (name) {
  if (store.state.canvasInfo.selectLayerList.length === 0) return
  switch (name) {
    case '左对齐':
      layerGroupAlignLeft()
      break
    case '水平居中对齐':
      layerGroupAlignCenterX()
      break
    case '右对齐':
      layerGroupAlignRight()
      break
    case '顶部对齐':
      layerGroupAlignTop()
      break
    case '垂直居中对齐':
      layerGroupAlignCenterY()
      break
    case '底部对齐':
      layerGroupAlignBottom()
      break
  }
}

/**
 * 图层对齐 - 左
 */
export function layerGroupAlignLeft () {
  const canvas = store.state.boxCanvas
  // 单对象
  if (canvas.getActiveObjects().length === 1) {
    store.commit('updateCanvasLayerById', { left: 0 })
    event.emit('updateCopyedLayerStyle', { left: 0 })
    return
  }
  // 多对象
  const activeObject = canvas.getActiveObject()
  const selectObjects = canvas.getActiveObjects()
  const { left } = activeObject
  canvas.discardActiveObject()
  selectObjects.forEach((item) => {
    const bounding = item.getBoundingRect(true)
    item.set({
      left: left - bounding.left + item.left
    })
    item.setCoords()
    // 多选操作时，需要复制的图层也要进行更改
    const copyedLayer = canvas._objects.filter(a => a.id.includes(item.id + '~copy~'))
    if (copyedLayer.length) {
      copyedLayer.forEach(t => {
        // const bounding = t.getBoundingRect(true);
        t.set({
          left: left - bounding.left + t.left
        })
      })
    }
  })
  const activeSelection = new fabric.ActiveSelection(selectObjects, {
    canvas: canvas
  })
  canvas.setActiveObject(activeSelection)
  canvas.requestRenderAll()
}

/**
 * 图层对齐 - 水平居中
 */
export function layerGroupAlignCenterX () {
  const canvas = store.state.boxCanvas
  const activeObject = canvas.getActiveObject()
  const selectObjects = canvas.getActiveObjects()
  // 单对象
  if (canvas.getActiveObjects().length === 1) {
    const canvasWidth = store.state.canvasInfo.width
    const layerWidth = activeObject.width * activeObject.scaleX
    const centreLeft = Math.floor((canvasWidth - layerWidth) / 2)
    activeObject.left = centreLeft
    event.emit('updateCopyedLayerStyle', { left: centreLeft })
    canvas.requestRenderAll()
    return
  }
  // 多对象
  const { left, width } = activeObject
  canvas.discardActiveObject()
  selectObjects.forEach((item) => {
    const bounding = item.getBoundingRect(true)
    item.set({
      left: left + width / 2 - (bounding.left + bounding.width / 2) + item.left
    })
    // 多选操作时，需要复制的图层也要进行更改
    const copyedLayer = canvas._objects.filter(a => a.id.includes(item.id + '~copy~'))
    if (copyedLayer.length) {
      copyedLayer.forEach(t => {
        t.set({
          left: left + width / 2 - (bounding.left + bounding.width / 2) + t.left
        })
      })
    }
  })
  const activeSelection = new fabric.ActiveSelection(selectObjects, {
    canvas: canvas
  })
  canvas.setActiveObject(activeSelection)
  canvas.requestRenderAll()
}

/**
 * 图层对齐 - 右
 */
export function layerGroupAlignRight () {
  const canvas = store.state.boxCanvas
  const activeObject = canvas.getActiveObject()
  const selectObjects = canvas.getActiveObjects()
  // 单对象
  if (selectObjects.length === 1) {
    const canvasWidth = store.state.canvasInfo.width
    const layerWidth = activeObject.width * activeObject.scaleX
    const rightLeft = Math.floor(canvasWidth - layerWidth)
    activeObject.left = rightLeft
    event.emit('updateCopyedLayerStyle', { left: rightLeft })
    canvas.requestRenderAll()
    return
  }
  // 多对象
  const { left, width } = activeObject
  canvas.discardActiveObject()
  selectObjects.forEach((item) => {
    const bounding = item.getBoundingRect(true)
    item.set({
      left: left + width - (bounding.left + bounding.width) + item.left
    })
    // 多选操作时，需要复制的图层也要进行更改
    const copyedLayer = canvas._objects.filter(a => a.id.includes(item.id + '~copy~'))
    if (copyedLayer.length) {
      copyedLayer.forEach(t => {
        t.set({
          left: left + width - (bounding.left + bounding.width) + t.left
        })
      })
    }
  })
  const activeSelection = new fabric.ActiveSelection(selectObjects, {
    canvas: canvas
  })
  canvas.setActiveObject(activeSelection)
  canvas.requestRenderAll()
}

/**
 * 图层对齐 - 顶部
 */
export function layerGroupAlignTop () {
  const canvas = store.state.boxCanvas
  if (canvas.getActiveObjects().length === 1) {
    store.commit('updateCanvasLayerById', { top: 0 })
    event.emit('updateCopyedLayerStyle', { top: 0 })
    return
  }
  // 多对象
  const activeObject = canvas.getActiveObject()
  const selectObjects = canvas.getActiveObjects()
  const { top } = activeObject
  canvas.discardActiveObject()
  selectObjects.forEach((item) => {
    const bounding = item.getBoundingRect(true)
    item.set({
      top: top - bounding.top + item.top
    })
    // 多选操作时，需要复制的图层也要进行更改
    const copyedLayer = canvas._objects.filter(a => a.id.includes(item.id + '~copy~'))
    if (copyedLayer.length) {
      copyedLayer.forEach(t => {
        t.set({
          top: top - bounding.top + t.top
        })
      })
    }
  })
  const activeSelection = new fabric.ActiveSelection(selectObjects, {
    canvas: canvas
  })
  canvas.setActiveObject(activeSelection)
  canvas.requestRenderAll()
}

/**
 * 图层对齐 - 垂直居中
 */
export function layerGroupAlignCenterY () {
  const canvas = store.state.boxCanvas
  const activeObject = canvas.getActiveObject()
  const selectObjects = canvas.getActiveObjects()
  // 单对象
  if (canvas.getActiveObjects().length === 1) {
    const canvasHeight = store.state.canvasInfo.height
    const layerHeight = activeObject.height * activeObject.scaleY
    const centreTop = Math.floor((canvasHeight - layerHeight) / 2)
    activeObject.top = centreTop
    event.emit('updateCopyedLayerStyle', { top: centreTop })
    canvas.requestRenderAll()
    return
  }
  // 多对象
  const { top, height } = activeObject
  canvas.discardActiveObject()
  selectObjects.forEach((item) => {
    const bounding = item.getBoundingRect(true)
    item.set({
      top: top + height / 2 - (bounding.top + bounding.height / 2) + item.top
    })
    // 多选操作时，需要复制的图层也要进行更改
    const copyedLayer = canvas._objects.filter(a => a.id.includes(item.id + '~copy~'))
    if (copyedLayer.length) {
      copyedLayer.forEach(t => {
        t.set({
          top: top + height / 2 - (bounding.top + bounding.height / 2) + t.top
        })
      })
    }
  })
  const activeSelection = new fabric.ActiveSelection(selectObjects, {
    canvas: canvas
  })
  canvas.setActiveObject(activeSelection)
  canvas.requestRenderAll()
}

/**
 * 图层对齐 - 底部
 */
export function layerGroupAlignBottom () {
  const canvas = store.state.boxCanvas
  const activeObject = canvas.getActiveObject()
  const selectObjects = canvas.getActiveObjects()
  // 单对象
  if (canvas.getActiveObjects().length === 1) {
    const canvasHeight = store.state.canvasInfo.height
    const layerHeight = activeObject.height * activeObject.scaleY
    const bottomTop = Math.floor(canvasHeight - layerHeight)
    activeObject.top = bottomTop
    event.emit('updateCopyedLayerStyle', { top: bottomTop })
    canvas.requestRenderAll()
    return
  }
  // 多对象
  const { top, height } = activeObject
  canvas.discardActiveObject()
  selectObjects.forEach((item) => {
    const bounding = item.getBoundingRect(true)
    item.set({
      top: top + height - (bounding.top + bounding.height) + item.top
    })
    // 多选操作时，需要复制的图层也要进行更改
    const copyedLayer = canvas._objects.filter(a => a.id.includes(item.id + '~copy~'))
    if (copyedLayer.length) {
      copyedLayer.forEach(t => {
        t.set({
          top: top + height - (bounding.top + bounding.height) + t.top
        })
      })
    }
  })
  const activeSelection = new fabric.ActiveSelection(selectObjects, {
    canvas: canvas
  })
  canvas.setActiveObject(activeSelection)
  canvas.requestRenderAll()
}

/**
 * 图层上移
 * @param id 图层id
 */
const bringForwardById = id => {
  // 找出相关元素
  const updapteLayer = store.state.boxCanvas._objects.filter(a => a.id.includes(id))
  // 找出实际图层
  const updateIndex = store.state.boxCanvas._objects.findIndex(a => a.id === id)
  // 下一个图层，如果实际图层是最后一个  不移动，return
  if (updateIndex + 1 === store.state.boxCanvas._objects.length || updateIndex === -1) return
  // 找到下一个图层的id
  const nextId = store.state.boxCanvas._objects[updateIndex + 1].id
  let steps = 0
  if (nextId.includes('~copy~')) {
    steps = store.state.boxCanvas._objects.filter(a => a.id.includes(nextId.split('~copy~')[0])).length - 1
  }
  store.state.boxCanvas._objects = store.state.boxCanvas._objects.filter(a => !a.id.includes(id))
  const finallyUpdateIndex = store.state.boxCanvas._objects.findIndex(a => a.id === nextId)
  store.state.boxCanvas._objects.splice(finallyUpdateIndex + 1 + steps, 0, ...updapteLayer)
  store.state.boxCanvas.requestRenderAll()
  // 更新图层列表
  updateLayerList()
}

/**
 * 图层上移 - 通用
 * @param canvas 画布对象
 */
export function bringForward (canvas) {
  const id = canvas.getActiveObject().id
  // 单选
  if (id) {
    bringForwardById(id)
  }
  // 多选
  if (!id) {
    const ids = canvas.getActiveObject()._objects.map(a => a.id)
    const layers = []
    ids.forEach(a => {
      canvas._objects.forEach((d, index) => {
        if (d.id === a) {
          layers.push({
            layer: d,
            order: index
          })
        }
      })
    })
    canvas.discardActiveObject()
    layers.sort((a, b) => {
      return a.order - b.order
    })
    const activeSelection = new fabric.ActiveSelection(layers.map(a => a.layer), {
      canvas: canvas
    })
    canvas.setActiveObject(activeSelection)
    const lastIds = canvas.getActiveObject()._objects.map(a => a.id).reverse()
    lastIds.forEach((a) => {
      bringForwardById(a)
    })
  }
  // 更新图层列表
  updateLayerList()
}

/**
 * 图层下移
 * @param id 图层id
 */
const sendBackwardsById = id => {
  // 找出相关元素
  const updapteLayer = store.state.boxCanvas._objects.filter(a => a.id.includes(id))
  const updateIndex = store.state.boxCanvas._objects.findIndex(a => a.id.includes(id))
  // 第一个图层 不移动
  if (updateIndex === 1 || updateIndex === -1) return
  const prev = store.state.boxCanvas._objects[updateIndex - 1]
  const steps = store.state.boxCanvas._objects.filter(a => a.id.includes(prev.id)).length
  store.state.boxCanvas._objects = store.state.boxCanvas._objects.filter(a => !a.id.includes(id))
  const finallyUpdateIndex = store.state.boxCanvas._objects.findIndex(a => a.id === prev.id)
  store.state.boxCanvas._objects.splice(finallyUpdateIndex - steps + 1, 0, ...updapteLayer)
  store.state.boxCanvas.requestRenderAll()
  // 更新图层列表
  updateLayerList()
}

/**
 * 图层下移 - 通用
 * @param canvas 画布对象
 */
export function sendBackwards (canvas) {
  const id = canvas.getActiveObject().id
  // 单选
  if (id) {
    sendBackwardsById(id)
  }
  // 多选
  if (!id) {
    const ids = canvas.getActiveObject()._objects.map(a => a.id)
    const layers = []
    ids.forEach(a => {
      canvas._objects.forEach((d, index) => {
        if (d.id === a) {
          layers.push({
            layer: d,
            order: index
          })
        }
      })
    })
    canvas.discardActiveObject()
    layers.sort((a, b) => {
      return a.order - b.order
    })
    const activeSelection = new fabric.ActiveSelection(layers.map(a => a.layer), {
      canvas: canvas
    })
    canvas.setActiveObject(activeSelection)
    const lastIds = canvas.getActiveObject()._objects.map(a => a.id)
    lastIds.forEach((a) => {
      sendBackwardsById(a)
    })
  }
  // 更新图层列表
  updateLayerList()
}

/**
 * 图层置顶
 * @param canvas 画布对象
 */
export function bringToFront (canvas) {
  const id = canvas.getActiveObject().id
  // 单个
  if (id) {
    const realateLayer = canvas._objects.filter(a => a.id.includes(id))
    canvas._objects = canvas._objects.filter(a => !a.id.includes(id))
    canvas._objects = [...canvas._objects, ...realateLayer]
    store.state.boxCanvas.requestRenderAll()
  }
  if (!id) {
    const ids = canvas.getActiveObject()._objects.map(a => a.id)
    const layers = []
    ids.forEach(a => {
      canvas._objects.forEach((d, index) => {
        if (d.id === a) {
          layers.push({
            layer: d,
            order: index
          })
        }
      })
    })
    layers.sort((a, b) => {
      return a.order - b.order
    })
    layers.map(a => a.layer.id).forEach((a) => {
      const realateLayer = canvas._objects.filter(b => b.id.includes(a))
      canvas._objects = canvas._objects.filter(c => !c.id.includes(a))
      canvas._objects = [...canvas._objects, ...realateLayer]
      store.state.boxCanvas.requestRenderAll()
    })
  }
  // 更新图层列表
  updateLayerList()
}

/**
 * 图层置底
 * @param canvas 画布对象
 */
export function sendToBack (canvas) {
  const id = canvas.getActiveObject().id
  // 单选
  if (id) {
    const realateLayer = canvas._objects.filter(a => a.id.includes(canvas.getActiveObject().id))
    canvas._objects = canvas._objects.filter(a => !a.id.includes(canvas.getActiveObject().id))
    canvas._objects.splice(1, 0, ...realateLayer)
    store.state.boxCanvas.requestRenderAll()
  }
  // 多选
  if (!id) {
    const ids = canvas.getActiveObject()._objects.map(a => a.id)
    const layers = []
    ids.forEach(a => {
      canvas._objects.forEach((d, index) => {
        if (d.id === a) {
          layers.push({
            layer: d,
            order: index
          })
        }
      })
    })
    layers.sort((a, b) => {
      return b.order - a.order
    })
    layers.map(a => a.layer.id).forEach((a) => {
      const realateLayer = canvas._objects.filter(b => b.id.includes(a))
      canvas._objects = canvas._objects.filter(c => !c.id.includes(a))
      canvas._objects.splice(1, 0, ...realateLayer)
      store.state.boxCanvas.requestRenderAll()
    })
  }
  // 更新图层列表
  updateLayerList()
}

/**
 * 更新文字特效
 * @param position 属性名
 * @param number 属性增加值
 */
export function updateEffectsPosition (position, number) {
  const canvas = store.state.boxCanvas
  const activeObj = canvas.getActiveObject()
  // 单选
  if (activeObj.id) {
    const realateLayer = canvas._objects.filter(a => a.id.includes(activeObj.id + '~copy~'))
    if (realateLayer.length) {
      realateLayer.forEach(a => {
        a.set(position, a[position] + number)
      })
    }
  }
  // 多选
  if (!activeObj.id) {
    activeObj._objects.forEach(origin => {
      const realateLayer = canvas._objects.filter(a => a.id.includes(origin.id + '~copy~'))
      if (realateLayer.length) {
        realateLayer.forEach(a => {
          a.set(position, a[position] + number)
        })
      }
    })
  }
}

/**
 * 艺术字图层修改
 * @param e
 */
export function effectLayerModify (e) {
  if (['scaleX', 'scaleY', 'scale'].includes(e.action)) {
    const { action, target: { id, width, height, left, top, scaleX, scaleY } } = e
    const copyLayer = store.state.boxCanvas.getObjects().filter((item) => item.id.includes(id + '~copy'))
    if (copyLayer.length) {
      if (action === 'scaleX') {
        copyLayer.forEach(item => {
          const { offsetX, used } = item.usedInfo.stroke
          item.set('width', width)
          // 从左往右移动时需要更新位置
          item.set('left', used ? offsetX + left : left)
          item.set('scaleX', scaleX)
        })
      } else if (action === 'scaleY') {
        copyLayer.forEach(item => {
          const { offsetY, used } = item.usedInfo.stroke
          item.set('height', height)
          // 从左往右移动时需要更新位置
          item.set('top', used ? offsetY + top : top)
          item.set('scaleY', scaleY)
        })
      } else if (action === 'scale') {
        copyLayer.forEach(item => {
          const { offsetX, offsetY, used } = item.usedInfo.stroke
          item.set('width', width)
          // 从左往右移动时需要更新位置
          item.set('left', used ? offsetX + left : left)
          item.set('top', used ? offsetY + top : top)
          item.set('scaleX', scaleX)
          item.set('scaleY', scaleY)
        })
      }
    }
  }
}

/**
 * 刷新操作面板类型
 * @param resetOfType 重置前置条件 - 当前类型(setting设置, layer图层, colourMixing调色)
 * @param resetToType 重置后的类型
 */
const refreshOperationFaceType = function (resetOfType, resetToType) {
  if (!resetToType) resetToType = 'setting'
  if (resetOfType && resetOfType !== store.state.operationFaceType) return
  store.commit('setOperationFaceType', resetToType)
}

/**
 * 更新全局canvas对象
 */
export function updateCanvasInfo (canvas) {
  const myCanvas = canvas || store.state.boxCanvas
  if (!myCanvas) return
  // 刷新操作面板类型
  if (store.state.canvasInfo.selectIdList.length === 0) {
    refreshOperationFaceType('colourMixing')
  }
  // console.log('对象集合: ', myCanvas.getObjects())
  // 更新总画布对象
  store.commit('updateBoxCanvas', { boxCanvas: myCanvas })
}

/**
 * 更新图层列表
 */
export function updateLayerList () {
  const myCanvas = store.state.boxCanvas
  if (!myCanvas) return
  // 获取图层列表
  const layerList = []
  myCanvas.forEachObject(function (one) {
    if (one.id !== 'workspace') { layerList.unshift(one) }
  })
  // console.log('图层列表: ', layerList)
  // 更新总画布对象
  store.commit('updateBoxCanvas', { layerList: layerList })
}

export const layerVisible = (id) => {
  const myCanvas = store.state.boxCanvas
  if (!myCanvas) return
  const activeObj = myCanvas.getActiveObject()
  myCanvas.forEachObject(one => {
    if (one.id.includes(id)) {
      one.visible = !one.visible
    }
  })
  if (activeObj && (id === activeObj.id)) {
    myCanvas.discardActiveObject()
  }
  initControls(myCanvas)
  myCanvas.requestRenderAll()
  updateLayerList()

  // const activeObj = canvas.getActiveObject()
  // activeObj.visible = false
  // canvas.renderAll()
  // console.log(activeObj)
}
